<template>
    <layout-auth>
      <div class="px-138">
        <div class="mt-96 mb-136">
          <img src="@/assets/images/logo/dashboard-logo.svg" alt="">
        </div>
        <div class="mt-96">
            <app-heading-1 class="mt-5">Continue as</app-heading-1>
            <app-normal-text class="mb-2">Please select your curent role to continue</app-normal-text>
            <div class="">
              <app-simple-button class="mr-2 mb-1" variant="primary"  @click="loginAs('client')">Client</app-simple-button>
              <app-simple-button class="mr-4" variant="light"  @click="loginAs('service_provider')">Service Provider</app-simple-button>
          </div>
        </div>
      </div>
    </layout-auth>
  </template>
  
  <script>
  import LayoutAuth from '@/layouts/layout-auth.vue'
  import {
    BLink,BFormCheckbox, BFormGroup, BAlert
  } from 'bootstrap-vue'
  import AppHeading1 from '@/components/app-components/AppHeading1.vue'
  import AppNormalText from '@/components/app-components/AppNormalText.vue'
  import AppTextInput from '@/components/app-components/AppTextInput.vue'
  import AppPasswordInput from '@/components/app-components/AppPasswordInput.vue'
  import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, email } from '@validations'
  import {login, resend_otp} from '@/apis/auth'
  export default {
    components: {
      LayoutAuth,
      BAlert,
      BLink,
      BFormCheckbox,
      BFormGroup,
      AppHeading1,
      AppNormalText,
      AppTextInput,
      AppPasswordInput,
      AppSimpleButton,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        options: [
          { value: null, text: 'Please select your role' },
          { value: 'client', text: 'Client' },
          { value: 'vendor', text: 'Vendor' },
          { value: 'service_provider', text: 'Service Provider' },
          { value: 'broker', text: 'Broker'},
        ],
        user_data:{
          email:'',
          password:'',
          rememberMe: false
        },
        submitted:false,
        loading:false,
        errorMessage:'',
        // validations 
        
        required,
        email
      }
    },
    mounted(){
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    methods: {
        loginAs(role){
            let userData=JSON.parse(localStorage.getItem('user'))
            userData['role'] = role
            localStorage.setItem('user', JSON.stringify(userData))
            this.$router.replace('/')
        }
    },
  }
  /* eslint-disable global-require */
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  .mt-96{
    margin-top: 96px;
  }
  .mb-136{
    margin-bottom: 136px;
  }
  </style>
  